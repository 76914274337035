import React from 'react'
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import BIDRRForm from './BidRRForm';
import {
    account_name_api,
    crm_api,
    crm_api_bulk_update,
    prp_roles_api_bulk_update,
} from '../../config/apiList';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import { Box, Button, MenuItem, Slide, TextField } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload'
import XLSX from "xlsx";
import Modal from '../../shared/Modal/Modal';
import ExcelDateToJSDate from '../../utils/excelDateToJSDate';
import Dropzone from '../../shared/Dropzone/Dropzone';
import moment from 'moment';
import { weekStartDates } from '../../utils/betweenDates';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CRMEdit from './CRMEdit';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Auth from '../../utils/authenticate';
import { RotateCircleLoading } from 'react-loadingg';
import { ArchiveOutlined } from '@material-ui/icons';
import AutcompleteInput from '../../shared/AutocompleteInput/Autocomplete';
import BusinessIcon from '@material-ui/icons/Business';
import { Autocomplete } from '@material-ui/lab';
// import EditIcon from "@material-ui/icons/Edit";
import CountryCode from './CountryCode';


const token = Auth.token()
const user = Auth.user()
// const url = '/api/crm/crm_update/'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const typeOptions = [{ id: 1, name: 'Site Contact', key: 'site_contact' }, { id: 2, name: 'Site CC', key: 'site_cc' }]

const Title = (props) => {
    return (
        <div>
            <h2 style={{ float: 'left' }}>CRM Table</h2>&emsp;&emsp;
            {props.access.l1a && props.access.l1a.l1a_d ?
                <Button
                    variant="outlined"
                    size="small"
                    style={{ marginTop: '15px', marginLeft: '80px' }}
                    onClick={props.onButtonClick}
                >
                    Upload CRM Data Excel&ensp;<UploadIcon />
                </Button>
                : null}
        </div>
    )
}

class CRMTable extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [],
            data: [],
            archive_column: [],
            isLoading: false,
            modelOpen: false,
            accountNameList: {},
            salesStageList: {},
            gateLevelList: {},
            subSectorList: {},
            marketSubVerticalList: {},
            ownerDeliverUnitList: {},
            ownerRegionList: {},
            opportunityTypeList: {},
            selectedOpportunity: {},
            accountNameListArr: [],
            reviewModal: false,
            dataToUpload: [],
            dropZoneModal: false,
            dateChangeDialog: false,
            empUpdateCount: 0,
            daysShift: 0,
            ittDaysDiff: 0,
            salesStatusList: {
                1: 'Order Received',
                2: 'Client Cancelled',
                3: 'Complete',
                4: 'Gate 7',
                5: 'Gate 8',
                6: 'In Progress',
                7: 'Mobilise',
                8: 'No Activity',
                9: 'On Hold',
                10: 'Operational',
                11: 'Submitted',
                12: 'Successful',
                13: 'Unsuccessful',
                14: 'Withdrawn',
            },
            editModal: false,
            view_type: 'Live',
            archive: [],
            modalType: '',
            deleteDialog: false,
            archiveDialog: false,
            unArchiveDialog: false,
            archiveReason: null,
            templateData: [],
            editTemplateData: {
                'template': '',
                'site_address': ''
            },
            contactData: [],
            configData: [],
            contactDialog: false,
            users: [],
            newContactData: {},
            title: '',
            dialogTitle: '',
            disabled: false,
            contactType: 'add',
            siteAddressDialog: false,
            changeSiteAddressDialog: false,
            siteAddressTableLoading: false
        }
        this.getCountryCode = this.getCountryCode.bind(this)
        this.countryCodeRef = React.createRef()

    }

    getAccountNameList() {
        return new Promise((resolve, reject) => {
            Service.get(account_name_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })

    }

    getAllList() {
        return new Promise((resolve, reject) => {
            Service.get('/api/crm/crmlinkedcolumns_api', {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            Service.get('/api/ptd/employees/employee_data/', {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: ele.name } }
        })
        return obj
    }

    getCountryCode(v) {
        this.setState({
            newContactData: {
                ...this.state.newContactData,
                contact_country_code: v.phone
            }
        }, () => {
            if (this.countryCodeRef && this.countryCodeRef.current) {
                this.countryCodeRef.current.setCountryCodeRef(v)
            }
        })
    }

    componentDidMount() {
        console.log(this.props.access)
        this.setState({ isLoading: true })
        Promise.all([
            this.getAccountNameList(),
            this.getAllList(),
            this.getUsers()
        ]).then(
            res => {
                this.setState({
                    accountNameListArr: res[0],
                    accountNameList: this.getFomattedLookupObj(res[0]),
                    salesStageList: this.getFomattedLookupObj(res[1]['sales_stage']),
                    gateLevelList: this.getFomattedLookupObj(res[1]['gate_stage']),
                    subSectorList: this.getFomattedLookupObj(res[1]['subsector']),
                    marketSubVerticalList: this.getFomattedLookupObj(res[1]['marketsubvertical']),
                    ownerDeliverUnitList: this.getFomattedLookupObj(res[1]['ownerdelivery']),
                    ownerRegionList: this.getFomattedLookupObj(res[1]['ownerregion']),
                    opportunityTypeList: this.getFomattedLookupObj(res[1]['opportunitytype']),
                    salesStatusList: this.getFomattedLookupObj(res[1]['salesstatus']),
                    users: res[2]
                })
                this.accountNameArr = res[0];
                this.salesStageArr = res[1]['sales_stage']
                this.gateLevelArr = res[1]['gate_stage'];
                this.subSectorArr = res[1]['subsector'];
                this.marketSubVerticalArr = res[1]['marketsubvertical'];
                this.ownerDeliveryArr = res[1]['ownerdelivery'];
                this.ownerResionArr = res[1]['ownerregion'];
                this.opportunityTypeArr = res[1]['opportunitytype'];
                this.salesStatusArr = res[1]['salesstatus']
                this.setColumns();
            }
        ).catch(e => {
            this.setColumns();
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    setColumns() {
        let column = [
            { title: 'Opportunity Id', field: 'opportunity_id', width: 150 },
            { title: 'Contract No', field: 'contract_no', width: 200 },
            { title: 'Opportunity Name', field: 'name', width: 250 },
            { title: 'Project Code', field: 'project_code', width: 250 },
            {
                title: 'Project Color', field: 'color_code', width: 100, render: (rowData) => <div style={{
                    background: rowData.color_code ? rowData.color_code : '', width: '3rem', height: '1rem'
                }}></div>,
            },

            { title: 'Gate Level Achieved', field: 'gate_level_acheived', lookup: this.state.gateLevelList, width: 200 },
            { title: 'ITT Due Date', field: 'itt_due_date', type: 'date', width: 200 },
            { title: 'Tender Submit Date', field: 'tender_submit_date', type: 'date', width: 200 },
            { title: 'Contract Start Date', field: 'contractor_start_date', type: 'date', width: 200 },
            { title: 'Contract End Date', field: 'contractor_end_date', type: 'date', width: 200 },
            { title: 'Account Name', field: 'account_name', lookup: this.state.accountNameList, width: 200 },
            { title: 'Sales Stage', field: 'sales_stage', lookup: this.state.salesStageList, width: 200 },
            { title: 'Sales Status', field: 'sales_status', lookup: this.state.salesStatusList, width: 200 },
            {
                title: 'Tender Value', field: 'tender_value', type: 'numeric',
                render: rowData => <span>{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.bb_tender_value ? rowData.bb_tender_value : 0)}</span>,
                width: 200
            },
            {
                title: 'BB Project Value', field: 'bb_project_value', type: 'numeric',
                render: rowData => <span>{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.bb_project_value ? rowData.bb_project_value : 0)}</span>,
                width: 200
            },
            { title: 'ITT Start Date', field: 'itt_start_date', type: 'date', width: 200 },
            { title: 'Sales Lead', field: 'sales_lead', width: 200 },
            { title: 'Expected Award Date', field: 'expected_award_date', type: 'date', width: 200 },
            // { title: 'Sub Sector', field: 'sub_sector', lookup: this.state.subSectorList, width: 200 },
            { title: 'Discipline', field: 'sub_sector', lookup: this.state.subSectorList, width: 200 },
            { title: 'Scope of Work', field: 'scope_of_work', width: 500 },
            {
                title: 'Submission Margin %', field: 'submission_margin', type: 'numeric',
                render: rowData => <span>{rowData.submission_margin ? rowData.submission_margin + ' %' : ''}</span>,
                width: 200
            },
            { title: 'Market Sub Verticle', field: 'market_sub_vertical', lookup: this.state.marketSubVerticalList, width: 200 },
            { title: 'Owner Delivery Unit', field: 'owner_delivery', lookup: this.state.ownerDeliverUnitList, width: 200 },
            { title: 'Owner Region/Sector', field: 'owner_region_sector', lookup: this.state.ownerRegionList, width: 200 },
            { title: 'Description', field: 'description', width: 200 },
            { title: 'Opportunity Type', field: 'opportunity_type', lookup: this.state.opportunityTypeList, width: 200 },
            { title: 'Location', field: 'location', width: 200 },
            { title: 'Start on Site Date', field: 'start_on_site_date', type: 'date', width: 200 },
            {
                title: 'Contract Award Value', field: 'contract_award_value', type: 'numeric',
                render: rowData => <span>{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.contract_award_value)}</span>,
                width: 200
            },
        ]

        let archive_column = [...column];
        archive_column.push({
            title: 'Archive Reason', field: 'archive_reason', width: 200,
            lookup: {
                1: 'Lost', 2: 'Withdrawn', 3: 'Client cancelled', 4: 'Complete'
            }
        })
        this.setState({ columns: column, archive_column: archive_column });
        this.getCRMTableData()
        this.fileToRead = this.fileToRead.bind(this)
    }

    getCRMTableData() {
        Service.get(crm_api + '?is_trashed=false', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let data = res.data;
                let archive = []; let live = [];
                data.forEach(opportunity => {
                    if (opportunity.archive_reason > 0) {
                        archive.push(opportunity)
                    }
                    else live.push(opportunity)
                })
                this.setState({ data: live, archive: archive }, () => {
                    this.setState({ isLoading: false })
                })
            }).catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }


    postCRMData(data) {
        this.setState({ isLoading: true })
        Service.post(crm_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.setState({ isLoading: false })
                this.getCRMTableData()
                this.props.enqueueSnackbar('Opportunity successfully added', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });

            })
    }

    putOps(data) {
        this.setState({ isLoading: true })
        Service.put(crm_api + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data,
        })
            .then(res => {
                this.getCRMTableData()
                this.props.enqueueSnackbar('Opportunity successfully updated', {
                    variant: 'success'
                });
                this.setState({ isLoading: false })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    deleteOps(data) {
        this.setState({ isLoading: true })
        Service.delete(crm_api + data.id, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.getCRMTableData()
                this.props.enqueueSnackbar('Deleted successfully', {
                    variant: 'success'
                });
                this.setState({ isLoading: false })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchOps();
            });
    }

    rowClicked = (e, rowdata) => {
        if (rowdata.gate_level_acheived === 3 || rowdata.gate_level_acheived === 4) {
            this.setState({ selectedOpportunity: rowdata }, () => {
                this.handleClickOpen()
            })

        }
        else {
            this.props.enqueueSnackbar('BID form can open only at Gate level 3', {
                variant: 'error'
            });
        }
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = (refreshValue) => {
        this.setState({ modelOpen: false })
        if (refreshValue === true) {
            this.setState({ isLoading: true })
            this.getCRMTableData()
        }
    }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)

                data.forEach(ele => {
                    if (ele.contractor_start_date) ele.contractor_start_date = ExcelDateToJSDate(ele.contractor_start_date);
                    if (ele.contractor_end_date) ele.contractor_end_date = ExcelDateToJSDate(ele.contractor_end_date);
                    if (ele.expected_award_date) ele.expected_award_date = ExcelDateToJSDate(ele.expected_award_date);
                    if (ele.itt_due_date) ele.itt_due_date = ExcelDateToJSDate(ele.itt_due_date);
                    if (ele.start_on_site_date) ele.start_on_site_date = ExcelDateToJSDate(ele.start_on_site_date);
                    if (ele.tender_submit_date) ele.tender_submit_date = ExcelDateToJSDate(ele.tender_submit_date);
                    if (String(ele.submission_margin).includes('%')) ele.submission_margin = ele.submission_margin.replace('%', '');
                    if (ele.account_name) {
                        for (let k in this.state.accountNameList) {
                            if (String(this.state.accountNameList[k]).trim() === String(ele.account_name).trim()) {
                                ele.account_name = k
                            }
                        }
                    }
                    if (ele.sales_stage) {
                        for (let k in this.state.salesStageList) {
                            if (String(this.state.salesStageList[k]).trim() === String(ele.sales_stage).trim()) {
                                ele.sales_stage = k
                            }
                        }
                    }
                    if (ele.sub_sector) {
                        for (let k in this.state.subSectorList) {
                            if (String(this.state.subSectorList[k]).trim() === String(ele.sub_sector).trim()) {
                                ele.sub_sector = k
                            }
                        }
                    }
                    if (ele.owner_delivery) {
                        for (let k in this.state.ownerDeliverUnitList) {
                            if (String(this.state.ownerDeliverUnitList[k]).trim() === String(ele.owner_delivery).trim()) {
                                ele.owner_delivery = k
                            }
                        }
                    }
                    if (ele.owner_region_sector) {
                        for (let k in this.state.ownerRegionList) {
                            if (String(this.state.ownerRegionList[k]).trim() === String(ele.owner_region_sector).trim()) {
                                ele.owner_region_sector = k
                            }
                        }
                    }
                    if (ele.opportunity_type) {
                        for (let k in this.state.opportunityTypeList) {
                            if (String(this.state.opportunityTypeList[k]).trim() === String(ele.opportunity_type).trim()) {
                                ele.opportunity_type = k
                            }
                        }
                    }
                    if (ele.gate_level_acheived) {
                        for (let k in this.state.gateLevelList) {
                            if (String(this.state.gateLevelList[k]).trim() === String(ele.gate_level_acheived).trim()) {
                                ele.gate_level_acheived = k
                            }
                        }
                    }
                });

                this.setState({ dataToUpload: data })
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    bulkUpload = () => {
        this.setState({ bulkUploadLoader: true })
        let dataToUpload = [...this.state.dataToUpload]
        let dataToUpdate = []
        dataToUpload.forEach((ele, i) => {
            this.state.data.forEach(op => {
                if (ele.opportunity_id === op.opportunity_id) {
                    ele.id = op.id;
                    dataToUpdate.push(ele)
                }
            })
        })
        dataToUpdate.forEach((ele) => {
            dataToUpload.forEach((ele2, i) => {
                if (ele.opportunity_id === ele2.opportunity_id) {
                    dataToUpload.splice(i, 1)
                }
            })
        })

        if (dataToUpload.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpload.length; i++) {
                arr.push(dataToUpload[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.crmBulkPost(arr)
                    arr = []
                }
                if (i === dataToUpload.length) {
                    this.crmBulkPost(arr)
                }
            }


        }
        if (dataToUpdate.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpdate.length; i++) {
                arr.push(dataToUpdate[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.crmBulkUpdate(arr)
                    arr = []
                }
                if (i === dataToUpdate.length && arr.length) {
                    this.crmBulkUpdate(arr)
                }
            }
        }

    }

    crmBulkPost(data) {
        Service.post(crm_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Row' : ' Rows') + ' successfully added.', {
                    variant: 'success'
                })

                this.setState({
                    reviewModal: false,
                    dataToUpload: [],
                    dropZoneModal: false,
                    bulkUploadLoader: false
                })
                this.getCRMTableData()
            })
            .catch(error => {
                console.error(error)
                this.setState({ bulkUploadLoader: false })
                if (!error || error.status !== 400) {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
                if (error.status === 400) {
                    if (error.data.length) {
                        for (let i = 0; i < error.data.length; i++) {
                            const err = error.data[i]
                            let hasError = Object.keys(err).length !== 0 && err.constructor === Object;
                            if (hasError) {
                                for (const e in err) {
                                    this.props.enqueueSnackbar("Upload error : " + (data[i]['opportunity_id'] ? data[i]['opportunity_id'] : '') + " - " + e + " (" + err[e] + ")", {
                                        variant: 'error'
                                    });
                                    return;
                                }
                            }
                        }

                    }
                }
            })

    }



    crmBulkUpdate(data) {
        Service.patch(crm_api_bulk_update, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Row' : ' Rows') + ' successfully updated.', {
                    variant: 'success'
                });
                this.setState({
                    reviewModal: false,
                    dataToUpload: [],
                    dropZoneModal: false,
                    bulkUploadLoader: false
                })
                this.getCRMTableData()
            })
            .catch(e => {
                console.error(e)
                this.setState({ bulkUploadLoader: false })
                if (e.status === 400) {
                    let error = e.data;
                    for (const key in error) {
                        this.props.enqueueSnackbar('Update Error : ' + key + ' - ' + error[key][0], {
                            variant: 'error'
                        });
                    }
                }
                else {
                    this.props.enqueueSnackbar('Something went wrong. Please try later', {
                        variant: 'error'
                    });
                }
            })
    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }

    async shiftDate(allEmpData, oldData, newData, sameConStartDate, sameITTStartDate) {
        let conDaysDiff = moment(newData.contractor_start_date).diff(moment(oldData.contractor_start_date), 'days')
        let ittDaysDiff = moment(newData.itt_start_date).diff(moment(oldData.itt_start_date), 'days')
        let data = [...allEmpData]
        let count = 0
        try {
            let allEmpUpdated = []
            data.forEach(role => {
                role.emp.forEach(emp => {
                    if (!sameConStartDate && emp.type === 1) {
                        if (emp.shdl_data) {
                            count++
                            let shdlData = [...emp.shdl_data];
                            let newDates = {};
                            let newshdl = []
                            shdlData.forEach(shdl => {
                                let toDate = moment(shdl.to).add(conDaysDiff, 'days').format()
                                let fromDate = moment(shdl.from).add(conDaysDiff, 'days').format()
                                let qty = shdl.qty
                                newshdl.push({ from: fromDate, to: toDate, qty: qty })
                                let dates = weekStartDates(fromDate, toDate);
                                dates.forEach(date => {
                                    newDates = { ...newDates, [date]: shdl.qty }
                                })

                            })
                            emp['shdl_data'] = newshdl;
                            emp['dates'] = newDates;
                        }
                    }
                    if (!sameITTStartDate && emp.type === 2) {
                        if (emp.shdl_data) {
                            count++
                            let shdlData = [...emp.shdl_data];
                            let newDates = {};
                            let newshdl = []
                            shdlData.forEach(shdl => {
                                let toDate = moment(shdl.to).add(ittDaysDiff, 'days').format()
                                let fromDate = moment(shdl.from).add(ittDaysDiff, 'days').format()
                                let qty = shdl.qty
                                newshdl.push({ from: fromDate, to: toDate, qty: qty })
                                let dates = weekStartDates(fromDate, toDate);
                                dates.forEach(date => {
                                    newDates = { ...newDates, [date]: shdl.qty }
                                })

                            })
                            emp['shdl_data'] = newshdl;
                            emp['dates'] = newDates;
                        }
                    }
                    allEmpUpdated.push(emp)
                })
            });

            this.updatedEmployees = allEmpUpdated;
            this.newData = newData;
            this.setState({ empUpdateCount: count, dateChangeDialog: true, daysShift: conDaysDiff, ittDaysDiff: ittDaysDiff })
        }
        catch (e) {

        }
    }

    async continueUdate() {
        // this.setState({ isLoading: true })
        this.setState({ isLoading: true })
        this.dateChangeDialogClose()
        let res = await this.bulkEmployeeUpdate(this.updatedEmployees)
        if (res.success === true) {
            this.putOps(this.newData)
        }
        else {
            this.setState({ isLoading: false })
        }
    }

    bulkEmployeeUpdate(data) {
        return new Promise((resolve, reject) => {
            Service.patch(prp_roles_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {
                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully updated', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    reject({ success: false })
                })
        })
    }

    dateChangeDialogClose = () => {
        this.setState({ dateChangeDialog: false })
    }

    editModalClose = (refreshValue) => {
        this.setState({ editModal: false })
        if (refreshValue === true) {
            this.setState({ isLoading: true })
            this.getCRMTableData()
        }
    }

    handleViewType(e) {
        this.setState({ view_type: e.target.value })
    }

    deleteDialogClose = () => {
        this.setState({ deleteDialog: false })
    }

    archiveDialogClose = () => {
        this.setState({ archiveDialog: false })
    }

    createNewContact = () => {
        if (this.state.newContactData.contact.length === 0 || this.state.newContactData.key === '') {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            let contact_id = []
            for (let i = 0; i < this.state.newContactData.contact.length; i++) {
                contact_id.push(this.state.newContactData.contact[i].id)
            }
            Service.post('/api/reporting/information/contact/add_multiple_contact/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'config_id': this.state.selectedConfig.id,
                    'type': this.state.newContactData.type.key,
                    'contact': contact_id,
                    'created_by': user.id,
                    'crm': this.state.selectedOpportunity.id
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Added Contact Successfully', {
                        variant: 'success'
                    });
                    this.setState({ contactDialog: false }, () => {
                        this.getContactData()
                        this.getConfigData()
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    createNewConfig = () => {
        if (Object.keys(this.state.editTemplateData).length === 0 || this.state.editTemplateData.hasOwnProperty('template') === false || this.state.editTemplateData.site_address === "") {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        }
        else {
            this.setState({
                disabled: true
            })
            Service.post(`/api/reporting/information/config/?crm=${this.state.selectedOpportunity.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'crm': this.state.selectedOpportunity.id,
                    'template': this.state.editTemplateData.template.id,
                    'site_address': this.state.editTemplateData.site_address,
                    'created_by': user.id,
                    'template_id': this.state.editTemplateData.template.id
                }
            })
                .then(res => {
                    this.setState({ templateDialog: false, disabled: false }, () => {
                        this.getConfigData()
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    getTemplateData(rowData) {
        Service.get('/api/reporting/instruction/template/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let editTemplate = {}
                if (rowData) {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        if (element.id === rowData.template) {
                            editTemplate = {
                                'template': element,
                                'site_address': rowData.site_address
                            }
                        }
                    }
                }
                this.setState({
                    templateData: res.data,
                    editTemplateData: editTemplate,
                    templateDialog: true
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    getContactData() {
        Service.get(`/api/reporting/information/contact/?config__crm=${this.state.selectedOpportunity.id}&config=${this.state.selectedConfig.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    contactData: res.data,
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    getConfigData() {
        Service.get(`/api/reporting/information/config/?crm=${this.state.selectedOpportunity.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                console.log(res.data)
                if (res.data.length === 0) {
                    this.setState({
                        dialogTitle: 'Add Template and Site Address'
                    })
                    this.getTemplateData()
                    this.getContactData()
                } else {
                    this.setState({
                        configData: res.data,
                        siteAddressDialog: true,
                        siteAddressTableLoading: false,
                    })
                }
            })
            .catch(e => {
                console.error(e)
            })
    }

    editTemplateData = (rowData) => {
        this.setState({
            dialogTitle: 'Edit Template/ Site Address',
        }, () => {
            this.getContactData()
            this.getTemplateData(rowData)
        })
    }

    editConfig = () => {
        if (Object.keys(this.state.editTemplateData).length === 0 || this.state.editTemplateData.hasOwnProperty('template') === false || this.state.editTemplateData.site_address === "") {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({
                disabled: true
            })
            Service.patch(`/api/reporting/information/config/${this.state.configData[0].id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'template': this.state.editTemplateData.template.id,
                    'site_address': this.state.editTemplateData.site_address,
                    'created_by': user.id,
                    'template_id': this.state.editTemplateData.template.id
                }
            })

                .then(res => {
                    this.setState({ templateDialog: false, disabled: false }, () => {
                        this.getConfigData()
                    })
                }
                )
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
                )
        }
    }

    updateContact = () => {
        console.log(this.state.newContactData)
        if (this.state.newContactData.contact.length === 0 || this.state.newContactData.type.key === "" || (this.state.newContactData.contact_number === null || this.state.newContactData.contact_country_code === null)) {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({
                tableDataLoading: true
            })
            Service.patch(`/api/reporting/information/contact/${this.state.newContactData.id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'type': this.state.newContactData.type.key,
                    'contact_number': this.state.newContactData.contact_number,
                    'contact_country_code': this.state.newContactData.contact_country_code,
                    'contact': this.state.newContactData.contact.id,
                    'first_name': this.state.newContactData.contact.first_name,
                    'last_name': this.state.newContactData.contact.last_name,
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Updated Contact Successfully', {
                        variant: 'success'
                    });
                    this.setState({
                        newContactData: { type: '', contact: [] },
                        contactDialog: false
                    }, () => {
                        this.getContactData()
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    deleteContact = (oldData) => {
        this.setState({
            tableDataLoading: true
        })
        Service.delete(`/api/reporting/information/contact/${oldData.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Contact Successfully', {
                    variant: 'success'
                });
                this.getContactData()
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    submitSiteAddress = () => {
        Service.post(`/api/reporting/information/config/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                'crm': this.state.selectedOpportunity.id,
                'site_address': this.state.newSiteAddress,
                'created_by': user.id,
                'template': this.state.configData[0].template
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Site Address changed successfully', {
                    variant: 'success'
                });
                this.setState({ siteAddressDialog: false, changeSiteAddressDialog: false }, () => {
                    this.getConfigData()
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    editSiteAddress = () => {
        this.setState({
            siteAddressTableLoading: true
        })
        Service.patch(`/api/reporting/information/config/${this.state.selectedConfig.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                'site_address': this.state.newSiteAddress
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Site Address changed successfully', {
                    variant: 'success'
                });
                this.setState({ siteAddressDialog: false, changeSiteAddressDialog: false }, () => {
                    this.getConfigData()
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

     deleteConfig = (oldData) => {
            this.setState({
                siteAddressTableLoading: true
            })
            Service.delete(`/api/reporting/information/config/${oldData.id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('Deleted Site Address Successfully', {
                        variant: 'success'
                    });
                    this.getConfigData()
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
  
    


    render() {
        const access = this.props.access.access

        const handleChangeSiteAddressDialog = () => {
            this.setState({ changeSiteAddressDialog: false })
        }

        return (
            <>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div>
                        {!this.props.uiRefresh ?
                            <FormControl fullWidth={true} style={{ marginBottom: '10px' }}>
                                <InputLabel>Live/Archive</InputLabel>
                                <Select
                                    labelId="view_type_label"
                                    id="view_type"
                                    value={this.state.view_type}
                                    onChange={this.handleViewType.bind(this)}
                                >
                                    <MenuItem value={'Live'}>Live</MenuItem>
                                    <MenuItem value={'Archive'}>Archive</MenuItem>
                                </Select>
                            </FormControl> : null}
                        {this.props.title === 'Live' ?
                            <MaterialTable
                                title={
                                    <Title access={access} onButtonClick={() => this.setState({ dropZoneModal: true })} />
                                    // 'CRM'
                                }
                                options={{
                                    // paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - (window.screen.height * 20 / 100),
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    // actionsColumnIndex: -1
                                    fixedColumns: {
                                        left: 1,
                                        right: 0
                                    },
                                    filtering: true,
                                    padding: 'dense'
                                }}
                                columns={this.state.columns}
                                data={this.state.data ? this.state.data : this.props.data}
                                isLoading={this.state.isLoading}
                                actions={access.l1a && access.l1a.l1a_c ? [
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => {
                                            this.setState({ selectedOpportunity: rowData, modalType: 'edit', editModal: true })

                                        }
                                    },
                                    {
                                        icon: 'delete_outline',
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => {
                                            let data = { ...rowData }
                                            data.is_trashed = true
                                            // this.putOps(data)
                                            this.deleteData = data
                                            this.setState({ deleteDialog: true })
                                        }
                                    },
                                    {
                                        icon: () => <ArchiveOutlined />,
                                        tooltip: 'Archive',
                                        onClick: (event, rowData) => {
                                            this.archiveData = rowData
                                            this.setState({ archiveDialog: true })
                                        }
                                    },

                                    {
                                        icon: 'add',
                                        tooltip: 'Add User',
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            this.setState({ modalType: 'add', editModal: true })
                                        }
                                    },
                                    {
                                        icon: () => <BusinessIcon />,
                                        tooltip: 'Site Schedule',
                                        hidden: !(access.l1a && access.l1a.l1a_g),
                                        onClick: (event, rowData) => {
                                            this.setState({ selectedOpportunity: rowData, }, () => {
                                                // this.getContactData()
                                                this.getConfigData(rowData)
                                            })
                                        }
                                    }
                                ] : []}

                                onRowClick={access.l1a && access.l1a.l1a_e ? (e, rowData) => this.rowClicked(e, rowData) : undefined}
                            />
                            : null}

                        {this.props.title === 'Archive' ?
                            <MaterialTable
                                title={<h2>Archived</h2>}
                                options={{
                                    // paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - (window.screen.height * 20 / 100),
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    fixedColumns: {
                                        left: 1,
                                        right: 0
                                    },
                                    filtering: true,
                                    padding: 'dense'
                                }}
                                columns={this.state.archive_column}
                                data={this.state.archive}
                                isLoading={this.state.isLoading}
                                actions={access.l1a && access.l1a.l1a_c ? [
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => {
                                            this.setState({ selectedOpportunity: rowData, editModal: true })

                                        }
                                    },
                                    {
                                        icon: () => <ArchiveOutlined style={{ transform: 'rotate(180deg)' }} />,
                                        tooltip: 'Unarchive',
                                        onClick: (event, rowData) => {
                                            this.unArchiveData = rowData
                                            this.setState({ unArchiveDialog: true })
                                        }
                                    },
                                    {
                                        icon: 'delete_outline',
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => {
                                            let data = { ...rowData }
                                            data.is_trashed = true
                                            // this.putOps(data)
                                            this.deleteData = data
                                            this.setState({ deleteDialog: true })
                                        }
                                    },
                                    {
                                        icon: () => <BusinessIcon />,
                                        tooltip: 'Site Schedule',
                                        onClick: (event, rowData) => {
                                            this.setState({ selectedOpportunity: rowData }, () => {
                                                // this.getContactData()
                                                this.getConfigData(rowData)
                                            })
                                        }
                                    }

                                ] : []}

                            // onRowClick={(e, rowData) => { this.rowClicked(e, rowData) }}
                            /> : null}

                        {this.state.modelOpen ?
                            <BIDRRForm
                                project={this.state.selectedOpportunity}
                                clientNames={this.state.accountNameListArr}
                                open={this.state.modelOpen}
                                onClose={this.handleClose}
                                access={access}
                            />
                            : null}

                        {this.state.editModal ?
                            <CRMEdit
                                project={this.state.selectedOpportunity}
                                clientNames={this.state.accountNameListArr}
                                open={this.state.editModal}
                                onClose={this.editModalClose}
                                accountNameArr={this.accountNameArr}
                                salesStageArr={this.salesStageArr}
                                gateLevelArr={this.gateLevelArr}
                                subSectorArr={this.subSectorArr}
                                marketSubVerticalArr={this.marketSubVerticalArr}
                                ownerDeliveryArr={this.ownerDeliveryArr}
                                ownerResionArr={this.ownerResionArr}
                                opportunityTypeArr={this.opportunityTypeArr}
                                salesStatusArr={this.salesStatusArr}
                                type={this.state.modalType}
                            />
                            : null}

                        <Dropzone
                            open={this.state.dropZoneModal}
                            onClose={this.dropzoneClose}
                            onChange={(e) => this.fileToRead(e)}
                            preview={this.reviewModalOpen}
                        />

                        <Modal
                            open={this.state.reviewModal}
                            close={this.reviewModalClose}
                            onUpload={this.bulkUpload}
                            columns={this.state.columns}
                            data={this.state.dataToUpload}
                            loading={this.state.bulkUploadLoader}
                            title={this.props.tableName}
                        />

                        <Dialog
                            open={this.state.deleteDialog}
                            onClose={this.deleteDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Confirm to delete?"}</DialogTitle>
                            <DialogContent>
                                {this.deleteData ?
                                    <DialogContentText id="alert-dialog-description">
                                        {"Opportunity Id : " + this.deleteData.opportunity_id}<br />

                                        {"Opportunity Name : " + this.deleteData.name}
                                    </DialogContentText>
                                    : null}
                                <DialogContentText id="alert-dialog-description">
                                    {"Confirm to delete opportunity  from table."}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.deleteDialogClose} color='secondary'>
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    this.setState({ deleteDialog: false });
                                    this.putOps(this.deleteData)
                                }} color="primary"
                                    autoFocus
                                    disabled={!this.deleteData}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Dialog
                            open={this.state.archiveDialog}
                            onClose={this.archiveDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Move to acrhive</DialogTitle>
                            <DialogContent>
                                {this.archiveData ?
                                    <DialogContentText id="alert-dialog-description">
                                        {"Opportunity Id : " + this.archiveData.opportunity_id}<br />

                                        {"Opportunity Name : " + this.archiveData.name}
                                    </DialogContentText>
                                    : null}
                                <DialogContentText id="alert-dialog-description">
                                    {"Select reason and confirm to move opportunity in archive."}
                                </DialogContentText>

                                <AutcompleteInput
                                    id="archive_reason"
                                    label="Archive Reason"
                                    options={[
                                        { id: 1, name: 'Lost' },
                                        { id: 2, name: 'Withdrawn' },
                                        { id: 3, name: 'Client cancelled' },
                                        { id: 4, name: 'Complete' }
                                    ]}
                                    onChange={(e, v) => this.setState({ archiveReason: v })}
                                    optionLabel={option => option.name}
                                    value={this.state.archiveReason}
                                    variant={'standard'}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.archiveDialogClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    this.setState({ archiveDialog: false });
                                    let data = { ...this.archiveData, ...{ archive_reason: this.state.archiveReason.id } }
                                    this.putOps(data)
                                }} color="primary"
                                    autoFocus
                                    disabled={!this.state.archiveReason}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.unArchiveDialog}
                            onClose={() => this.setState({ unArchiveDialog: false })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Move to live</DialogTitle>
                            <DialogContent>
                                {this.unArchiveData ?
                                    <DialogContentText id="alert-dialog-description">
                                        {"Opportunity Id : " + this.unArchiveData.opportunity_id}<br />

                                        {"Opportunity Name : " + this.unArchiveData.name}
                                    </DialogContentText>
                                    : null}
                            </DialogContent>
                            <DialogActions>
                                <Button onClose={() => this.setState({ unArchiveDialog: false })} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    this.setState({ unArchiveDialog: false });
                                    let data = { ...this.unArchiveData, ...{ archive_reason: 0 } }
                                    this.putOps(data)
                                }} color="primary"
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            maxWidth={'xl'}
                            open={this.state.configDialog}
                            onClose={() => this.setState({ configDialog: false })}
                        >
                            {/* <DialogTitle id="alert-dialog-title">Move to live</DialogTitle> */}
                            <DialogContent>
                                {/* <Box marginBottom={'10px'}>
                                    {this.state.configData.map(ele => {
                                        return (
                                            <Box display={'flex'} flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
                                                <Box display='flex' flexDirection='column'>
                                                    <Box display={'flex'} flexDirection='row'>
                                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{'Template: '}</Typography><Typography variant="body1">{ele.template_name}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} flexDirection='row'>
                                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{'Site Address: '}</Typography><Typography variant="body1">{ele.site_address}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display={'flex'} flexDirection='row'>
                                                    <IconButton onClick={() => {
                                                        this.editTemplateData(ele)
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box> */}
                                <MaterialTable
                                    title="Contact"
                                    data={this.state.contactData}
                                    columns={[
                                        {
                                            title: 'Contact',
                                            field: 'first_name',
                                            render: rowData => rowData.first_name + ' ' + rowData.last_name,

                                        },
                                        { title: 'Contact Type', field: 'type', lookup: { 'site_contact': 'Site Contact', 'site_cc': 'Site CC' } },
                                        { title: 'Country Code', field: 'contact_country_code' },
                                        { title: 'Contact Number', field: 'contact_number' },
                                    ]}
                                    options={{
                                        headerStyle: {
                                            fontWeight: 'bold',
                                        },
                                        filtering: true,
                                        padding: 'dense',
                                        paging: false
                                    }}
                                    actions={[
                                        ({
                                            icon: 'add',
                                            tooltip: 'Add Contact',
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                this.setState({ contactType: 'add', contactDialog: true })
                                            }
                                        }),
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                console.log(rowData)
                                                let contact = this.state.users.filter(ele => rowData.contact === ele.id)
                                                let type = typeOptions.find(ele => rowData.type === ele.key)
                                                this.setState({
                                                    newContactData: {
                                                        type: type,
                                                        contact: contact,
                                                        id: rowData.id,
                                                        contact_country_code: rowData.contact_country_code,
                                                        contact_number: rowData.contact_number
                                                    }
                                                    , contactType: 'edit'
                                                }, () => {
                                                    this.setState({ contactDialog: true })
                                                    console.log(this.state.newContactData)
                                                })
                                            }
                                        })
                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise((resolve, reject) => {
                                                this.deleteContact(oldData)
                                                resolve();
                                            })
                                    }}
                                />
                            </DialogContent>
                            {/* <DialogActions>
                                <Button onClose={() => this.setState({ configDialog: false })} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    this.setState({ unArchiveDialog: false });
                                    let data = { ...this.unArchiveData, ...{ archive_reason: 0 } }
                                    this.putOps(data)
                                }} color="primary"
                                >
                                    Confirm
                                </Button>
                            </DialogActions> */}
                        </Dialog>
                        <Dialog
                            TransitionComponent={Transition}
                            maxWidth={'xl'}
                            open={this.state.siteAddressDialog}
                            onClose={() => this.setState({ siteAddressDialog: false })}
                        >
                            <DialogContent>
                                <MaterialTable
                                    title="Site Address"
                                    data={this.state.configData}
                                    isLoading={this.state.siteAddressTableLoading}
                                    columns={[
                                        { title: 'Template Name', field: 'template_name' },
                                        { title: 'Site Address', field: 'site_address' },
                                    ]}
                                    options={{
                                        headerStyle: {
                                            fontWeight: 'bold',
                                        },
                                        filtering: true,
                                        padding: 'dense',
                                        paging: false
                                    }}
                                    onRowClick={(event, rowData) => { this.setState({ selectedConfig: rowData, configDialog: true }, () => {this.getContactData()}) }}
                                    actions={[
                                        ({
                                            icon: 'add',
                                            tooltip: 'Add Contact',
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                this.setState({ siteAddressType: 'add', newSiteAddress: ''  }, () => {
                                                    this.setState({ changeSiteAddressDialog: true })
                                                })
                                            }
                                        }),
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                this.setState({
                                                    newSiteAddress: rowData.site_address,
                                                    selectedConfig: rowData,
                                                    changeSiteAddressDialog: true,
                                                    siteAddressType: 'edit'
                                                })
                                            }
                                        })
                                    ]}
                                    // editable={{
                                    //     onRowDelete: oldData =>
                                    //         new Promise((resolve, reject) => {
                                    //             this.deleteConfig(oldData)
                                    //             resolve();
                                    //         })
                                    // }}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.templateDialog}
                            onClose={() => this.setState({ templateDialog: false })}
                        >
                            <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                                    <Autocomplete
                                        options={this.state.templateData}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: 300 }}
                                        defaultValue={this.state.editTemplateData.template}
                                        onChange={(e, v) => {
                                            if (v)
                                                this.setState({ editTemplateData: { ...this.state.editTemplateData, template: v } })
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Template" variant="outlined" />}
                                    />
                                    <Box marginTop={"10px"}>
                                        <TextField
                                            variant='outlined'
                                            label='Site Address'
                                            defaultValue={this.state.editTemplateData.site_address}
                                            style={{ width: 300 }}
                                            onChange={(e) => {
                                                this.setState({ editTemplateData: { ...this.state.editTemplateData, site_address: e.target.value } })
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({
                                    templateDialog: false,
                                    editTemplateData: {
                                        'template': '',
                                        'site_address': ''
                                    }
                                })} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    disabled={this.state.disabled}
                                    onClick={() => { if (this.state.dialogTitle === 'Add Template and Site Address') { this.createNewConfig() } else { this.editConfig() } }}
                                    color="primary"
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.contactDialog}
                            onClose={() => this.setState({ contactDialog: false })}
                        >
                            <DialogTitle id="alert-dialog-title">{this.state.contactType === "add" ? 'Add New Contact' : 'Edit Contact'}</DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                                    <Autocomplete
                                        options={typeOptions}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: 300 }}
                                        value={this.state.newContactData?.type}
                                        onChange={(e, v) => {
                                            if (v)
                                                this.setState({ newContactData: { ...this.state.newContactData, type: v } })
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Contact Type" variant="outlined" />}
                                    />

                                    <Box marginTop={"10px"}>
                                        <Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            options={this.state.users}
                                            getOptionLabel={(option) => option.last_name + ', ' + option.first_name}
                                            style={{ width: 300 }}
                                            value={this.state.newContactData?.contact}
                                            onChange={(e, v) => {
                                                if (v)
                                                    this.setState({ newContactData: { ...this.state.newContactData, contact: v } })
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Contact" variant="outlined" />}
                                        />
                                    </Box>
                                    {this.state.contactType === 'edit'
                                        //  && this.state.newContactData?.type.key === 'site_contact'

                                        ?
                                        <Box display="flex" flexDirection='column'>
                                            <Box marginTop={"10px"}>
                                                <CountryCode
                                                    ref={this.countryCodeRef}
                                                    newContactData={this.state.newContactData} handleCountryCode={this.getCountryCode} />

                                            </Box>
                                            <Box marginTop="10px">
                                                <TextField
                                                    label='Phone Number'
                                                    variant='outlined'
                                                    style={{ width: 300 }}
                                                    defaultValue={this.state.newContactData.contact_number}
                                                    onChange={(e) => this.setState({ newContactData: { ...this.state.newContactData, contact_number: e.target.value } })}
                                                />
                                            </Box>
                                        </Box>
                                        : null}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ contactDialog: false, newContactData: { type: '', contact: [] } })} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => { this.state.contactType === 'add' ? this.createNewContact() : this.updateContact() }} color="primary"
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.changeSiteAddressDialog} onClose={handleChangeSiteAddressDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{this.state.siteAddressType === 'add' ? 'Add Site Address' : 'Edit Site Address'}</DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                                    <Box marginTop={"10px"}>
                                        <TextField
                                            variant='outlined'
                                            label='Site Address'
                                            value={this.state.newSiteAddress}
                                            style={{ width: 300 }}
                                            onChange={(e) => {
                                                this.setState({ newSiteAddress: e.target.value })
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleChangeSiteAddressDialog} color="primary" variant='contained'>
                                    Cancel
                                </Button>
                                <Button onClick={() => { this.state.siteAddressType === 'add' ? this.submitSiteAddress() : this.editSiteAddress() }} color="primary" variant='contained' autoFocus>
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </>
        )
    }
}

export default withSnackbar(CRMTable)
