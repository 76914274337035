import { AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, ListItem, Popover, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import CloseIcon from '@material-ui/icons/Close';
import ROAPReportHome from '../../Reports/ROAPReport/ROAPReportHome';
import ResourceLocation from '../../Reports/ResourceLocation/ResourceLocation';
import OHLHomeClass from '../../Reports/OHL/OHLHomeClass';
import DvsCReports from '../../DvsCReports.js/DvsCReports';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../../utils/CookieHelper';
import Service from '../../../config/networkutils';
import { CoffeeLoading } from 'react-loadingg';
import StandBy from '../../Reports/StandyBy/StandBy';
import ReorderIcon from '@material-ui/icons/Reorder';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReportingInstructions from '../../Reports/ReportingInstructions/ReportingInstructions';
import UnallocatedCRM from '../../Reports/UnallocatedDemand/UnallocatedCRM';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const token = getCookie("ptd_ts_token")

const reports = ["Demand vs Capacity", "ROAP", "Staff Location"]

// const ohlroles = [
//     58, - General Foreman
//     65, - Head of Operations
//     171, - Apprentice General Foreman (a)
//     124, - Senior General Foreman
//     45, - Driver 1
//     138, - Storeperson
//     203, - Foreman
//     196, - General Operatives
//     204, - Foreman 2 (a)
//     205, - LE1
//     206, - LE2
//     207, - Leading Hand
//     208, - Apprentice Over Head Line Technician
//     209, - Overhead Lines Trainee
//     210, - SPO
//     270, - Driver 2
// ]

// const civilsroles = [
//19, - FFM (Civils)  
//20, - Foreman (Civils) (a)
//218, - GO (Civils)
//219, - TM1 
//220, - TM2 
//221, - TM3 
//271 -  General Foreman (Civils)
//]

// var gfRoleId = [58, 124]
// var gfRoleIdCivils = [271]

const component = {
    0: <DvsCReports />,
    1: <ROAPReportHome />,
    2: <ResourceLocation />,
    // 3: <OHLHomeClass roles={ohlroles} gfRoleId={gfRoleId} discipline="ohl" />,
    // 4: <OHLHomeClass roles={civilsroles} gfRoleId={gfRoleIdCivils} discipline="civils" />
}

class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            title: '',
            index: 0,
            gangOpen: false,
            discipline: '',
            roles: [],
            gfRoleId: [],
            data: [],
            anchorEl: null,
            coffeeLoading: false,
            otherReportsDialog: false,
            buttonDisabled: true,
            selectedGang: null,
            gangStructure: {},
            toggleView: 'role',
            unallocatedToggleView: 'role',
            unallocatedDemandDialog: false
        }
        this.handleDrawerRef = React.createRef();
        this.toggleButtonGroup = React.createRef();
        this.handleLoading = this.handleLoading.bind(this);
        this.changeViewParent = this.changeViewParent.bind(this);
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({ toggleView: 'role' })
        Service.get(`/api/crm/get_unallocated_demand_crm/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        // `/api/prp/template/get_crm_unallocated_schedules/?crm=`
        this.fetchGangStructures();
        if (this.props.access && this.props.access.access && this.props.access.access.l10a && this.props.access.access.l10a.l10a_a) {
            if (!reports.includes("Reporting Instructions")) {
                reports.push("Reporting Instructions")
            }
        }
    }

    fetchGangStructures = () => {
        Service.get(`/api/gang/report/roles/get_category_with_roles/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (!reports.includes("Gang Structures")) {
                    reports.push("Gang Structures")
                }
                if (!reports.includes("Reporting Instructions")) {
                    reports.push('Reporting Instructions')
                }
                if (!reports.includes('Unallocated Demand')) {
                    reports.push('Unallocated Demand')
                }
                if (!reports.includes('Other Reports')) {
                    reports.push('Other Reports')
                }
                this.setState({ data: res.data });
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleStaffLocClose = () => {
        this.setState({ openStaffLoc: false })
    }

    handleReportInstructionClose = () => {
        this.setState({ openReportInstruction: false })
    }

    openComponent = (e, title, index) => {
        if (title === "Gang Structures") {
            if (this.state.data.length > 0) {
                this.setState({
                    anchorEl: e.currentTarget
                })
            } else {
                this.props.enqueueSnackbar('No Gang Structures found!', {
                    variant: 'warning'
                });
            }
        } else if (title === "Other Reports") {
            this.setState({ otherReportsDialog: true })
        } else if (title === "Staff Location") {
            this.setState({ openStaffLoc: true, toggleView: 'role' })
        } else if (title === "Reporting Instructions") {
            this.setState({ openReportInstruction: true })
        } else if (title === "Unallocated Demand") {
            this.setState({ unallocatedDemandDialog: true })
        } else {
            this.setState({
                open: true,
                title: title,
                index: index
            })
        }
    }

    handleGangClose = () => {
        this.setState({ gangOpen: false })
    }

    openGang = (e, item) => {
        this.setState({
            anchorEl: null,
            coffeeLoading: true
        }, () => {
            this.setState({
                roles: item.level_1,
                gfRoleId: item.level_0,
                discipline: item.key,
                gangOpen: true,
                title: item.name,
                coffeeLoading: false,
                selectedGang: item,
                gangStructure: item
                // coffeeLoading: false
            })
        })
    }

    handleDrawerOpen = () => {
        if (this.handleDrawerRef && this.handleDrawerRef.current) {
            this.handleDrawerRef.current.handleDrawerOpen();
        }
    }

    handleLoading = (value) => {
        if (!value) {
            this.setState({ coffeeLoading: value, buttonDisabled: false })
        } else {
            this.setState({ coffeeLoading: false, gangOpen: false, buttonDisabled: true })
            this.props.enqueueSnackbar('Fetching Gangs data failed!', {
                variant: 'error'
            });
        }
    }

    changeViewParent = (value) => {
        this.setState({ toggleView: value })
    }

    render() {

        const open = Boolean(this.state.anchorEl);

        const handleAnchorClose = () => {
            this.setState({ anchorEl: null })
        };

        const handleOtherReportsDialogClose = () => {
            this.setState({ otherReportsDialog: false })
        }

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ toggleView: newValue }, () => {
                    if (this.toggleButtonGroup && this.toggleButtonGroup.current) {
                        this.toggleButtonGroup.current.changeView(newValue);
                    }
                })
            }
        };

        const handleUnallocatedToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ unallocatedToggleView: newValue }, () => {
                    if (this.toggleButtonGroup && this.toggleButtonGroup.current) {
                        this.toggleButtonGroup.current.changeView(newValue);
                    }
                })
            }
        };

        return (
            <div>
                <div>
                    {reports.map((report, i) => {
                        return (
                            <div key={i}>
                                <ListItem
                                    key={i}
                                    style={{ padding: "10px" }}
                                    onClick={(e) => this.openComponent(e, report, i)}
                                    button>
                                    {report}
                                </ListItem>
                                <div>
                                    {i !== reports.length - 1 ?
                                        <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} /> : null}
                                </div>
                                <Popover
                                    // id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={handleAnchorClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    {this.state.data.map((item, index) => {
                                        return (
                                            <div style={{ width: 300 }} key={index}>
                                                <ListItem
                                                    key={index}
                                                    style={{ padding: "10px" }}
                                                    onClick={(e) => this.openGang(e, item)}
                                                    button>
                                                    {item.name}
                                                </ListItem>
                                                <Divider style={{ backgroundColor: getSecondaryColor() }} />
                                            </div>
                                        )
                                    })}
                                </Popover>
                            </div>
                        )
                    })
                    }
                </div >

                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        {component[this.state.index]}
                    </Box>
                </Dialog>

                <Dialog fullScreen open={this.state.openStaffLoc} onClose={this.handleStaffLocClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box flexGrow={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <IconButton edge="start" color="inherit" onClick={this.handleStaffLocClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6">
                                        Staff Location
                                    </Typography>
                                </Box>
                                <ThemeProvider theme={darkTheme}>
                                    <ToggleButtonGroup
                                        size="small"
                                        value={this.state.toggleView}
                                        onChange={handleToggle}
                                        exclusive
                                        aria-label="text formatting">
                                        <ToggleButton value={"role"} aria-label="to_lead">
                                            Sector/Region/Owner Delivery Unit/Role
                                        </ToggleButton>
                                        <ToggleButton value={"crm"} aria-label="to_em">
                                            CRM/Employee
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </ThemeProvider>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <ResourceLocation ref={this.toggleButtonGroup} changeViewParent={this.changeViewParent} />
                    </Box>
                </Dialog>

                <Dialog fullScreen open={this.state.gangOpen} onClose={this.handleGangClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box display={'flex'} flexDirection={'row'} flexGrow={1} alignItems={'center'}>
                                    <IconButton disabled={this.state.buttonDisabled} color="inherit" onClick={() => this.handleDrawerOpen()} style={{ marginRight: '10px' }}>
                                        <ReorderIcon />
                                    </IconButton>
                                    <Typography variant="h6">
                                        {this.state.title}
                                    </Typography>
                                </Box>
                                <IconButton color="inherit" onClick={this.handleGangClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <OHLHomeClass
                            discipline={this.state.discipline}
                            selectedGang={this.state.selectedGang}
                            gangStructure={this.state.gangStructure}
                            ref={this.handleDrawerRef}
                            roles={this.state.roles}
                            gfRoleId={this.state.gfRoleId}
                            handleLoading={this.handleLoading}
                        />
                    </Box>
                </Dialog>


                <Dialog fullWidth={true} maxWidth='lg' open={this.state.otherReportsDialog} >
                    <DialogTitle >
                        <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography variant="h5" >
                                    Other Reports
                                </Typography>
                            </Box>
                            <IconButton onClick={handleOtherReportsDialogClose} color='error'>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <StandBy />
                    </DialogContent>
                </Dialog>

                <Dialog fullScreen open={this.state.unallocatedDemandDialog} >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box display={'flex'} flexDirection={'row'} flexGrow={1} alignItems={'center'}>
                                    <IconButton color="inherit" onClick={() => this.setState({ unallocatedDemandDialog: false })} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6">
                                        Unallocated Demand
                                    </Typography>
                                </Box>
                            </Box>
                            <ThemeProvider theme={darkTheme}>
                                <ToggleButtonGroup
                                    size="small"
                                    value={this.state.unallocatedToggleView}
                                    onChange={handleUnallocatedToggle}
                                    exclusive
                                    style={{ color: 'inherit' }}
                                    aria-label="text formatting"
                                // color={'primary'}
                                >
                                    <ToggleButton value={"role"}>
                                        Role
                                    </ToggleButton>
                                    <ToggleButton value={"crm"}>
                                        CRM
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </ThemeProvider>
                        </Toolbar>
                    </AppBar>
                    <Box marginTop="70px">
                        <UnallocatedCRM access={this.props.access} ref={this.toggleButtonGroup} />
                    </Box>
                </Dialog>

                <Dialog open={this.state.coffeeLoading} maxWidth={"md"}>
                    <DialogContent style={{ padding: "70px" }}>
                        <CoffeeLoading color={getPrimaryColor()} />
                    </DialogContent>
                </Dialog>

                <Dialog fullScreen open={this.state.openReportInstruction} onClose={this.handleReportInstructionClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box flexGrow={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <IconButton edge="start" color="inherit" onClick={this.handleReportInstructionClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6">
                                        Reporting Instructions
                                    </Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <ReportingInstructions access={this.props.access} />
                    </Box>
                </Dialog>

            </div >
        )
    }
}
export default withSnackbar(Reports);